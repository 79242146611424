export const SLOT_BASE_FRAGMENT = `
  fragment slotBaseFragment on Exhibitor {
    uid
    slots: _slots"%filter%" {
      uid
      code
      quantity
      strategy
      type
    }
  }
`;
